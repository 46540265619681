<template>
  <section id="cta" class="cta">
      <div class="container" data-aos="zoom-in">
          <div class="row justify-content-center">
              <div class="col-lg-3 cta-btn-container text-center">
                <a class="cta-btn align-middle" href="#" @click.prevent="setLocale('en')">{{$t("english")}}</a>
                <a class="cta-btn align-middle" href="#" @click.prevent="setLocale('fr')">{{$t("french")}}</a>
            </div>
          </div>
        <div class="row">
          <div class="col-lg-12 text-center text-lg-center">
            <h3>{{$t("grm")}}</h3>
            <p>{{$t("introduction")}}</p>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import languages from "../data/languages"
export default {
    name : "LanguageSwitcher",
    data(){
        return {
            languages : languages.data
        }
    },
    methods : {
        setLocale(locale){
            if(locale != this.$i18n.locale)
            {
                 this.$i18n.locale = locale;
                 this.$router.push({
                    params: { lang: locale }
                });
                // this.$router.go();
            }
        }
    },
}
</script>

<style>
.content-justify-center{
    justify-content: center;
}
</style>
<template>
  <div id="app">
    <CTA/>
    <router-view />
    <div id="preloader"></div>
  </div>
</template>
<script>
import CTA from './components/CTA.vue'
export default {
  components: { CTA },
  metaInfo() {
    return { 
      title: this.$t("grm"),
      // htmlAttrs: {
      //   lang: this.$i18n.locale,
      //   amp: undefined
      // },
      meta: [
        { name: 'description', content:  ''},
        { property: 'og:title', content: this.$t("introduction")},
        { property: 'og:site_name', content: ''},
        {property: 'og:type', content: 'website'},    
        {name: 'robots', content: 'index,follow'} 
      ]
    }
  }
}
</script>



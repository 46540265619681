<template>
  <div class="home">
    
    <section id="contact" class="contact">
      
      <div class="container" data-aos="fade-up">
       
        <div class="section-title">
          <h2>{{$t("complete-the-form")}}</h2>
          <!-- <p>{{$t("introduction")}}</p> -->
        </div>

        <div class="row">
          <div class="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch">
            <form class="grm-form" @submit.prevent="send">
              <div class="form-row mb-2">
                <vs-switch v-model="form.anonymity" color="#47b2e4" @input="prompt">
                   <template #off>
                      {{$t("anonymity-mode", {state : $t("off")})}}
                  </template>
                  <template #on>
                      {{$t("anonymity-mode", {state : $t("on")})}}
                  </template>
                </vs-switch>
              </div>
              <div id="hide">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="surname">{{$t("surname")}}*</label>
                    <input type="text" :class="`form-control is-${errors.surname == null ? '' : 'invalid'}`" v-model="form.surname" />
                    <div class="invalid-feedback" v-if="errors.surname!= null">{{$t(errors.surname[0])}}</div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="other-names">{{$t("other-names")}}*</label>
                    <input type="text" :class="`form-control is-${errors.other_names == null ? '' : 'invalid'}`" v-model="form.other_names" id="other_names"/>
                    <div class="invalid-feedback" v-if="errors.other_names!= null">{{$t(errors.other_names[0])}}</div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="age">{{$t("age")}}*</label>
                    <input type="number" :class="`form-control is-${errors.age == null ? '' : 'invalid'}`" @input="ageChecks()" v-model="form.age"/>
                    <div class="invalid-feedback" v-if="errors.age!= null">{{$t(errors.age[0])}}</div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="gender">{{$t("gender")}}*</label>
                    <select :class="`form-control is-${errors.gender == null ? '' : 'invalid'}`" v-model="form.gender">
                      <option value="female">{{$t("female")}}</option>
                      <option value="male">{{$t("male")}}</option>
                      <option value="prefer-not-to-say">{{$t("prefer-not-to-say")}}</option>
                    </select>
                    <div class="invalid-feedback" v-if="errors.gender!= null">{{$t(errors.gender[0])}}</div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="profession">{{$t("profession")}}*</label>
                    <input type="text" :class="`form-control is-${errors.profession == null ? '' : 'invalid'}`" v-model="form.profession"/>
                    <div class="invalid-feedback" v-if="errors.profession!= null">{{$t(errors.profession[0])}}</div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="email">{{$t("email")}}*</label>
                    <input type="email" :class="`form-control is-${errors.email == null ? '' : 'invalid'}`" v-model="form.email"/>
                    <div class="invalid-feedback" v-if="errors.email!= null">{{$t(errors.email[0])}}</div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="phone">{{$t("phone")}}</label>
                    <vue-tel-input  v-model="form.phone" mode="international" :inputOptions="{placeholder:$t('enter-phone-number')}"></vue-tel-input>
                    <div class="invalid-feedback" v-if="errors.phone!= null">{{$t(errors.phone[0])}}</div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="whatsapp">{{$t("whatsapp")}}</label>
                    <vue-tel-input  v-model="form.whatsapp" mode="international" :inputOptions="{placeholder:$t('enter-whatsapp-number')}"></vue-tel-input>
                    <div class="invalid-feedback" v-if="errors.whatsapp!= null">{{$t(errors.whatsapp[0])}}</div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="country">{{$t("country")}}</label>
                    <select :class="`form-control is-${errors.country == null ? '' : 'invalid'}`" v-model="form.country" @change="getSelectedCountry">
                      <option selected disabled value="">{{$t("select-country")}}</option>
                      <option v-for="(country, c) in countries" :value="country.en" :key="`country-${c}`">{{country.en}}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="ace">{{$t("ace")}}*</label>
                    <select :class="`form-control is-${errors.ace == null ? '' : 'invalid'}`" v-model="current_ace">
                      <!-- <option selected disabled value="">{{$t("select-ace")}}</option> -->
                      <option v-for="(ace, a) in aces" :value="ace" :key="`ace-${a}`">{{ace.centre}}</option>
                    </select>
                    <div class="invalid-feedback" v-if="errors.ace!= null">{{$t(errors.ace[0])}}</div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="address">{{$t("address")}}*</label>
                    <textarea :class="`form-control is-${errors.address == null ? '' : 'invalid'}`" v-model="form.address" rows="10"></textarea>
                    <div class="invalid-feedback" v-if="errors.address!= null">{{$t(errors.address[0])}}</div>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="details">{{$t("details")}}*</label>
                  <textarea :class="`form-control is-${errors.details == null ? '' : 'invalid'}`" v-model="form.details" rows="10"></textarea>
                  <div class="invalid-feedback" v-if="errors.details!= null">{{$t(errors.details[0])}}</div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="solution">{{$t("solution")}}*</label>
                  <textarea :class="`form-control is-${errors.solution == null ? '' : 'invalid'}`" v-model="form.solution" rows="10"></textarea>
                  <div class="invalid-feedback" v-if="errors.solution!= null">{{$t(errors.solution[0])}}</div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="file">{{$t("file")}}</label>
                  <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot="true" v-on:vdropzone-success="uploadSuccess" v-on:vdropzone-error="uploadError" v-on:vdropzone-removed-file="fileRemoved">
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">{{$t("drag-and-drop")}}</h3>
                      <div class="subtitle">{{$t("click-to-add")}}</div>
                    </div>
                  </vue-dropzone>
                  <div class="dz-error-message" style="color:red"><span data-dz-errormessage></span></div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <vue-recaptcha :language="$i18n.locale" v-model="form.token" style="margin: 0 auto;display: table" :class="`form-group mb-3 ${errors.token == null ? '' : 'is-invalid'}`" @verify="onVerify" :sitekey="siteKey" :loadRecaptchaScript="true">
                  </vue-recaptcha>
                  <div class="invalid-feedback" v-if="errors.token != null">{{$t(errors.token[0])}}</div>
                </div>
              </div>
              <!-- <div class="mb-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your complaint has been sent. Thank you!</div>
              </div> -->
              <div class="text-center"><button type="submit">{{$t("send-complaint")}}</button></div>
            </form>
          </div>

        </div>

        <ContactInfo />
      </div>
    </section>

  </div>
</template>

<script>
// @ is an alias to /src
import ContactInfo from "@/components/ContactInfo.vue";
import { VueTelInput } from "vue-tel-input";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueRecaptcha from  "vue-recaptcha";
// import VueNumberInput from '@chenfengyuan/vue-number-input';
import axios from "axios";
import {toast} from "../utils/alert.util";
import aces from "../data/aces"

export default {
  name: "Home",
  components: {
    ContactInfo, VueTelInput, vueDropzone: vue2Dropzone, VueRecaptcha
    // "vue-number-input" : VueNumberInput
  },
  data(){
    return {
      countries : aces.data,
      form : {
        country : "Ghana",
        anonymity : false,
      },
      current_ace : {},
      errors : {},
      aces : {},
      dropzoneOptions : {
        url: `${process.env.VUE_APP_ENDPOINT}/file`,
        addRemoveLinks: true,
        maxFiles: 1,
        maxFilesize : 10,
        acceptedFiles : ".jpg,.jpeg,.png,.pdf"
      },
      siteKey : process.env.VUE_APP_RECAPTCHA_SITE_KEY
    }
  },
  methods : {
    async getSelectedCountry(){
      this.aces =  this.countries.find(country => country.en === this.form.country).aces;
    },
    
    ageChecks(){
      if(this.form.age < 0){
        this.form.age = null;
      }
    },
    prompt(){
       toast.show({
          icon : "info",
          title : this.form.anonymity ? this.$t('anonymity-on-text') : this.$t('anonymity-off-text')
      });
    },
    send(){
      this.errors = {};
      const loading = this.$vs.loading({
        color : "#47b2e4",
        type : "circles",
        text : this.$t("processing-please-wait")
      });
      //  if(this.form.anonymity){
        //  this.form.surname = "not-set";
        //  this.form.other_names = "not-set";
        //  this.form.age = 0;
        //  this.formgender = "not-set";
        //  this.form.profession = "not-set";
        //  this.form.phone = "not-set";
        // this.form.whatsapp = "not-set";
        // this.form.email = "not-set@aau.org";
        // this.form.address = "not-set";
        // this.form.ace = "not-set";
      //  }
      this.form.ace = this.current_ace.centre;
      this.form.acronym = this.current_ace.acronym;
      this.form.locale = this.$i18n.locale;
      this.form.source = "web";
      this.form.message = {
        subject  : this.$t('email-body.subject'),
        salutation : this.$t('email-body.salutation', {name : this.form.other_names}),
        main : this.$t('email-body.main'),
        note : this.$t('email-body.note')
      };
      axios.post(`${process.env.VUE_APP_ENDPOINT}/complaints`, this.form)
      .then(response => {
        loading.close();
        toast.show({
          icon : response.data.success ? "success" : "error",
          title : this.$t(response.data.message)
      });
      }).catch(e => {
        loading.close();
        switch (e.response.status) {
          case 422:
            this.errors = e.response.data.errors;
            toast.show({
                icon : "error",
                title : this.$t('fields-incorrectly-filled')
            });
            break;
        
          default:
            toast.show({
              icon : "error",
              title : this.$t("something-went-wrong", {code : e.response.status}),
            });
            break;
        }
      });
    },
    uploadSuccess(file, response) {
      this.form.file = response.message;
      console.log(file);
      console.log(response);
    },
     uploadError(file, message) {
      this.file_error  = message.errors.file[0];
    },
    fileRemoved() {
      axios.delete(`${process.env.VUE_APP_ENDPOINT}/file/${this.form.file}`)
      .then(response => {
        this.form.file = null;
        console.log(response)
      }).catch(e => {
        console.log(e)
      })
    },
    onVerify: function (response) {
      this.form.token = response;
      },
  },
  created(){
    this.getSelectedCountry();
     window.location.href = "https://grm.ace.aau.org";

  }
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>

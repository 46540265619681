<template>
  <div class="row">
    <div class="col-lg-12 d-flex align-items-stretch">
      <div class="info">
        <div class="address">
          <i class="icofont-google-map"></i>
          <h4>{{$t("location")}}:</h4>
          <p>Trinity Avenue, Accra - Ghana</p>
        </div>

        <div class="email">
          <i class="icofont-envelope"></i>
          <h4>{{$t("email")}}:</h4>
          <p>grievance@aau.org</p>
        </div>

        <div class="phone">
          <i class="icofont-brand-whatsapp"></i>
          <h4>{{$t("whatsapp")}}:</h4>
          <p>+233.264.173.153</p>
        </div>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.460346324799!2d-0.17230848523367012!3d5.646336695907005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9bae9abd3c45%3A0xd952672acb80358!2sAssociation%20of%20African%20Universities!5e0!3m2!1sen!2sgh!4v1623789325609!5m2!1sen!2sgh"
          frameborder="0"
          style="border: 0; width: 100%; height: 290px"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

export default {
  data: [
    {
      code: "en",
      alternate_code: "en-gb",
      name: "English",
      flag: "",
    },
    {
      code: "fr",
      alternate_code: "fr-fr",
      name: "Français",
      flag: "",
    },
  ],
};
